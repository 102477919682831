import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "FY22 新卒入社エンジニアはこの 1 年でどのような成長をしてきたのかインタビューしました",
  "date": "2024-04-05T14:41:06.000Z",
  "slug": "entry/2024/04/05/234106",
  "tags": [],
  "hero": "./2024_04_05.png",
  "heroAlt": "FY22 新卒入社エンジニアはこの 1 年でどのような成長をしてきたのかインタビューしました"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの新居です。今回は 2022 年新卒入社したエンジニア達にインタビューした記事をお送りします。`}</p>
    <p>{`この 1 年で彼らがどのような業務を経験してきたのかや、リアルな現在の立ち位置などをお伝えしたいと考えています。`}</p>
    <p>{`それでは、ご覧ください!`}</p>
    <h1>{`自己紹介`}</h1>
    <h2>{`飯田さん`}</h2>
    <p>{`研修後は`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`で顧客向けの機能開発を担当。その後`}<a parentName="p" {...{
        "href": "https://shigotalk.jp/"
      }}>{`シゴトーク`}</a>{`でインフラ領域を中心にリニューアルに従事する。現在は `}<a parentName="p" {...{
        "href": "https://us.job-medley.com/"
      }}>{`Jobley`}</a>{` で施策の開発やインフラ周りまで幅広く業務を担当。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/334099ae6a95c859db2342adada39c78/eea4a/_dev202404__003.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAX5mTxsAJ//EABoQAAICAwAAAAAAAAAAAAAAAAEDAAISMUT/2gAIAQEAAQUCdYBaX1ByMO+qf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAICAgMAAAAAAAAAAAAAAAABAhEQIRIycf/aAAgBAQAGPwJ3u9HBRZ1GR9x//8QAGhABAAMAAwAAAAAAAAAAAAAAAQAhMRCx8f/aAAgBAQABPyEYCMDYQIVq4+zjdSOz/9oADAMBAAIAAwAAABAX3//EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPxBQf//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxAdbb//xAAfEAACAQIHAAAAAAAAAAAAAAAAEQEx8BAhQWFxgaH/2gAIAQEAAT8QhcI0+oQpFKUzVF0MV34PWWO2F//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202404  003",
            "title": " dev202404  003",
            "src": "/static/334099ae6a95c859db2342adada39c78/e5166/_dev202404__003.jpg",
            "srcSet": ["/static/334099ae6a95c859db2342adada39c78/f93b5/_dev202404__003.jpg 300w", "/static/334099ae6a95c859db2342adada39c78/b4294/_dev202404__003.jpg 600w", "/static/334099ae6a95c859db2342adada39c78/e5166/_dev202404__003.jpg 1200w", "/static/334099ae6a95c859db2342adada39c78/eea4a/_dev202404__003.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`飯田さん`}</em></p>
    <h2>{`岡田さん`}</h2>
    <p>{`研修後はジョブメドレーでの開発業務を担当後、シゴトークへ。ジョブメドレーとの連携強化のプロジェクトなど経験し、現在はシゴトークの開発全般を担当。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/955f8a6ee6eaf11b8eab70c398175eca/eea4a/_dev202404__007.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABdwhxltkcP//EABsQAAEEAwAAAAAAAAAAAAAAAAMBAgQzABIy/9oACAEBAAEFAjnQbIpR5s1Fm9gtl3f/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECEQNxEiGB/9oACAEBAAY/AuLV2SSdM7ZHRj2eH//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQVFhofD/2gAIAQEAAT8hDWFyh1LdgZruLWC1fc+zzMXNqjebpP/aAAwDAQACAAMAAAAQUM//xAAXEQADAQAAAAAAAAAAAAAAAAAAESFh/9oACAEDAQE/EMGof//EABgRAAIDAAAAAAAAAAAAAAAAAAABESFB/9oACAECAQE/EMTIOz//xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhQVExobH/2gAIAQEAAT8QXgRKKOxBRYKUANHeysZmXixWZ6H6mpAVPKVL4xFGZ//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202404  007",
            "title": " dev202404  007",
            "src": "/static/955f8a6ee6eaf11b8eab70c398175eca/e5166/_dev202404__007.jpg",
            "srcSet": ["/static/955f8a6ee6eaf11b8eab70c398175eca/f93b5/_dev202404__007.jpg 300w", "/static/955f8a6ee6eaf11b8eab70c398175eca/b4294/_dev202404__007.jpg 600w", "/static/955f8a6ee6eaf11b8eab70c398175eca/e5166/_dev202404__007.jpg 1200w", "/static/955f8a6ee6eaf11b8eab70c398175eca/eea4a/_dev202404__007.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`岡田さん`}</em></p>
    <h2>{`徳永さん`}</h2>
    <p>{`研修後は`}<a parentName="p" {...{
        "href": "https://jm-academy.jp/"
      }}>{`ジョブメドレーアカデミー`}</a>{`に配属。プロダクト改善などを担当しつつ、直近では生成 AI を使った機能の開発やバックエンドからモバイルまで幅広い開発に従事。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/71b3d4e2b92e1744afea0e8b242bcaed/eea4a/_dev202404__011.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAHXxVBSLIv/xAAYEAEBAQEBAAAAAAAAAAAAAAACAwEzAP/aAAgBAQABBQJ1IEr5tKyKb5x4+//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPwGJj//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPwGrr//EABoQAAMAAwEAAAAAAAAAAAAAAAABEQIQMXH/2gAIAQEABj8CbthzpcWZj91//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAEhMRARUaH/2gAIAQEAAT8hblNFJkwe08kaWNPh5nhFFH//2gAMAwEAAgADAAAAEGjf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAwEBPxBQ4KGH/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARQf/aAAgBAgEBPxB0Lkh2/8QAHRABAQACAQUAAAAAAAAAAAAAAREAITFRYXGBsf/aAAgBAQABPxBxhSZXAAvlDdqr2xBjzC42lbye8IMG7+GapodDP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202404  011",
            "title": " dev202404  011",
            "src": "/static/71b3d4e2b92e1744afea0e8b242bcaed/e5166/_dev202404__011.jpg",
            "srcSet": ["/static/71b3d4e2b92e1744afea0e8b242bcaed/f93b5/_dev202404__011.jpg 300w", "/static/71b3d4e2b92e1744afea0e8b242bcaed/b4294/_dev202404__011.jpg 600w", "/static/71b3d4e2b92e1744afea0e8b242bcaed/e5166/_dev202404__011.jpg 1200w", "/static/71b3d4e2b92e1744afea0e8b242bcaed/eea4a/_dev202404__011.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`徳永さん`}</em></p>
    <h2>{`古川さん`}</h2>
    <p>{`研修後は `}<a parentName="p" {...{
        "href": "https://pharms-cloud.com/"
      }}>{`Pharms`}</a>{` に配属。フロントエンドからインフラまで幅広く担当。小規模施策の開発に始まり現在は中規模施策のプロジェクトリードをしながら開発を行なう。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5f5c61a162b063b4ea29f1fd053e1ce8/eea4a/_dev202404__014.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFx8MVdCsv/xAAbEAACAwADAAAAAAAAAAAAAAABAgADBCEyM//aAAgBAQABBQK2xQMzqI2nnT51dTP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEmH/2gAIAQMBAT8BxL//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEmH/2gAIAQIBAT8B1T//xAAaEAACAgMAAAAAAAAAAAAAAAAAARARITJh/9oACAEBAAY/Akx4dmjEX2P/xAAeEAACAQMFAAAAAAAAAAAAAAAAAREhMWFBUYGhwf/aAAgBAQABPyFxJpgTIkbMCJw5LWTt+HOhUuP/2gAMAwEAAgADAAAAEKA//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EJDD/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAECAQE/EFyO/8QAHBABAAIDAAMAAAAAAAAAAAAAAQARITFBUaGx/9oACAEBAAE/EGaQk2NkfYcF8U+uWVt0ryeondzALUK0dGHsbfLP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202404  014",
            "title": " dev202404  014",
            "src": "/static/5f5c61a162b063b4ea29f1fd053e1ce8/e5166/_dev202404__014.jpg",
            "srcSet": ["/static/5f5c61a162b063b4ea29f1fd053e1ce8/f93b5/_dev202404__014.jpg 300w", "/static/5f5c61a162b063b4ea29f1fd053e1ce8/b4294/_dev202404__014.jpg 600w", "/static/5f5c61a162b063b4ea29f1fd053e1ce8/e5166/_dev202404__014.jpg 1200w", "/static/5f5c61a162b063b4ea29f1fd053e1ce8/eea4a/_dev202404__014.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`古川さん`}</em></p>
    <h2>{`村上さん`}</h2>
    <p>{`研修後は `}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`CLINICS`}</a>{` に配属。電子カルテ関連の開発を経て、現在は中規模施策のプロジェクトリードをしながら開発を行なう。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fe805f304bfc98e0d75a7b2cc3aafa63/eea4a/_dev202404__006.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAF6ZQVVwxE//8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIAAxEEEjL/2gAIAQEAAQUC7LlVi1nTFAnJAETx/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABoQAAEFAQAAAAAAAAAAAAAAAAABAhEhMXH/2gAIAQEABj8C0WG0pUGDYQbw/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERMSFRYZGh/9oACAEBAAE/IXNlGoFhHzZHPYjQdGIiujxD/9oADAMBAAIAAwAAABBQ3//EABcRAAMBAAAAAAAAAAAAAAAAAAABEUH/2gAIAQMBAT8QTwh//8QAFhEBAQEAAAAAAAAAAAAAAAAAABFR/9oACAECAQE/ENK//8QAHBABAQADAAMBAAAAAAAAAAAAAREAITFR0eHw/9oACAEBAAE/EOpAoO0mCyYVa/JnIN2LfeKCrvwxzYd4l5jWi/DP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202404  006",
            "title": " dev202404  006",
            "src": "/static/fe805f304bfc98e0d75a7b2cc3aafa63/e5166/_dev202404__006.jpg",
            "srcSet": ["/static/fe805f304bfc98e0d75a7b2cc3aafa63/f93b5/_dev202404__006.jpg 300w", "/static/fe805f304bfc98e0d75a7b2cc3aafa63/b4294/_dev202404__006.jpg 600w", "/static/fe805f304bfc98e0d75a7b2cc3aafa63/e5166/_dev202404__006.jpg 1200w", "/static/fe805f304bfc98e0d75a7b2cc3aafa63/eea4a/_dev202404__006.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`村上さん`}</em></p>
    <h2>{`吉岡さん`}</h2>
    <p>{`研修後は`}<a parentName="p" {...{
        "href": "https://clinics-app.com/"
      }}>{`患者アプリ CLINICS`}</a>{` に配属。Web アプリとネイティブアプリの機能開発を担当した後、CLINICS に配属。現在は定常改善や、フロントエンドの大規模改修などに従事。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b5c5912520cd17f59fcc3b1e95af4a70/eea4a/_dev202404__010.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABg0rajZkEv//EABoQAAICAwAAAAAAAAAAAAAAAAECAAMEEDH/2gAIAQEAAQUCx6wEpArUmL19f//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/Aaj/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGK/8QAGBABAAMBAAAAAAAAAAAAAAAAAQAQETL/2gAIAQEABj8CTeolEa//xAAbEAACAgMBAAAAAAAAAAAAAAAAASExEBFRof/aAAgBAQABPyF2pxKYz0Ru0erA7P/aAAwDAQACAAMAAAAQVN//xAAWEQADAAAAAAAAAAAAAAAAAAAQMXH/2gAIAQMBAT8QSh//xAAYEQACAwAAAAAAAAAAAAAAAAABEBEhQf/aAAgBAgEBPxAWM4n/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhQTFhgbHR/9oACAEBAAE/EKuBKppDVdw3oS5fLvzPpExpxh7ibzjdmf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202404  010",
            "title": " dev202404  010",
            "src": "/static/b5c5912520cd17f59fcc3b1e95af4a70/e5166/_dev202404__010.jpg",
            "srcSet": ["/static/b5c5912520cd17f59fcc3b1e95af4a70/f93b5/_dev202404__010.jpg 300w", "/static/b5c5912520cd17f59fcc3b1e95af4a70/b4294/_dev202404__010.jpg 600w", "/static/b5c5912520cd17f59fcc3b1e95af4a70/e5166/_dev202404__010.jpg 1200w", "/static/b5c5912520cd17f59fcc3b1e95af4a70/eea4a/_dev202404__010.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`吉岡さん`}</em></p>
    <h1>{`ファーストキャリアとしてメドレーを選んだ理由について`}</h1>
    <p><strong parentName="p">{`──`}</strong>{` まずはファーストキャリアにメドレーへ入社を決めた理由をお伺いしていきたいと思います。`}</p>
    <p><strong parentName="p">{`飯田`}</strong>{`: 元々ベンチャー気質が高いことを軸に会社選びをしていました。新卒入社の社員が多数いるような規模が大きい会社だと、自分がその中に埋もれてしまい、自由度が高い動きができなさそうだと考えていたからです。メドレーは新卒が少ないのでその辺りはマッチしていました。医療という領域に関しても、`}<strong parentName="p">{`日常生活に関わるところで社会に貢献できるような領域が良いなと考えていたことや、家族が看護師をやっていることもあり医療業界の課題を身近に感じられ、医療に対して興味があった`}</strong>{`ことが理由になっています。`}</p>
    <p><strong parentName="p">{`岡田`}</strong>{`: 自分の体調が悪くなることが昔から多かったためヘルスケア業界に興味があったのですが、そこに近い業界だったのがひとつの理由です。また会社のミッションがとても分かりやすかったというのがあります。他社に比べて、メドレーは`}<strong parentName="p">{`世の中を良くしてくという会社のビジョンに、ちゃんと基づいてプロダクトを開発している`}</strong>{`んだなというのが具体的に分かって一貫性を感じられたのが大きいです。`}</p>
    <p>{`他には、「エンジニア」という職種だともちろん技術分野が専門領域になっていくわけですが、その領域に閉じず、プロダクトを中心に課題解決する上でエンジニアリング以外のことも考えながら開発をしているプロダクトファーストな考えも魅力的でした。`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: 自分は岡田くんのように特にヘルスケア領域に興味があったということはなかったんです。どちらかというと、`}<strong parentName="p">{`エンジニアとしてこれから働いていく上で自分が大切にしたい志向や価値観というのが、メドレーのエンジニアの方達と話をしていく中でマッチする`}</strong>{`なと感じたのが大きいです。`}</p>
    <p>{`それまでも色々な会社さんとの選考をしていたんですが、メドレーのエンジニアは「本質を追い求める」という姿勢の元、エンジニアリングが好きで、それをプロダクトへ還元していくという部分が自分のフィーリングにあったという感じでした。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` フィーリングに合ったということですが、他にもマッチすると思った部分はありましたか?`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: エンジニアの雰囲気も大人な感じの方が多く、体育会系の「気合と根性で頑張る」という進め方ではなく、合理的に物事を進めるために必要なコミュニケーションをきちんと取っていくところなどが魅力でした。そうした部分も含めて自分が働いている将来というのが想像できました。`}</p>
    <p><strong parentName="p">{`古川`}</strong>{`: 自分は親が薬剤師で医療分野に元々興味があり、医療 x IT という軸で就活をしていく過程でメドレーを知ったのが最初の出会いでした。お話を聞いていくうちに「課題解決のためのプロダクト作り」という点が非常に魅力に感じました。社会を良くするためにプロダクトを良くしていこうという姿勢で会社に一体感が生まれているのが、すごく良いなと。`}</p>
    <p>{`会社に限らないかもしれないですが、組織も人が多くなってくるとやっぱり方向性が少しずつズレていくという感じになりがちだと思うんですが、メドレーの場合は`}<strong parentName="p">{`会社として「医療業界をもっと良くしていくための事業をしていく」という軸が通っている`}</strong>{`のを感じて、最終的にはこの部分に惹かれて入社を決めました。`}</p>
    <p>{`あとは、上場企業という「安心感」もファーストキャリアの選択に影響しました。`}</p>
    <p><strong parentName="p">{`岡田`}</strong>{`: 自分も新卒入社の社員目線だと、入社する会社の安定感というのは重要だと思っていました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d27deb45a558498e7a1c2fa9211ade3b/eea4a/_dev202404__016.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAXrQ2xiAi//EABsQAAICAwEAAAAAAAAAAAAAAAEDERMAAhIy/9oACAEBAAEFAtnGyyFWGFYsDpnr/8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAEDAQE/AYy//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Bqv/EAB0QAQABAwUAAAAAAAAAAAAAAAEAAhARITFBYXH/2gAIAQEABj8CaDbqVJlVhpiHrExxb//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExUYGh0f/aAAgBAQABPyF2l2Ve55vZCoUg0dgHZfymKKplTln/2gAMAwEAAgADAAAAEKz/AP/EABURAQEAAAAAAAAAAAAAAAAAAAEA/9oACAEDAQE/ECAb/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERcf/aAAgBAgEBPxCaMv/EAB0QAQEAAwACAwAAAAAAAAAAAAERACExQXGBkcH/2gAIAQEAAT8Q0gb5Qeq1sN/WHD82mW7H8w8iBHXvzhslA0tMcMbEIfGA5cmf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202404  016",
            "title": " dev202404  016",
            "src": "/static/d27deb45a558498e7a1c2fa9211ade3b/e5166/_dev202404__016.jpg",
            "srcSet": ["/static/d27deb45a558498e7a1c2fa9211ade3b/f93b5/_dev202404__016.jpg 300w", "/static/d27deb45a558498e7a1c2fa9211ade3b/b4294/_dev202404__016.jpg 600w", "/static/d27deb45a558498e7a1c2fa9211ade3b/e5166/_dev202404__016.jpg 1200w", "/static/d27deb45a558498e7a1c2fa9211ade3b/eea4a/_dev202404__016.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`村上`}</strong>{`: 就活する前は特定の業界に限らず色々な会社を見ていました。学生時代に様々な業界のエンジニアインターンやアルバイトなどしていたのですが、その過程で「せっかく正社員で働くなら、より社会的意義がある事業をやるべきだな」と感じました。そうした意識で就活に臨んで`}<strong parentName="p">{`自分が興味を持って取り組めそうなのは医療業界だなと思った`}</strong>{`のが最初のきっかけでした。`}</p>
    <p>{`メドレーは選考の中で、技術領域を問わずに開発できることなどがマッチすると感じたのと、サービスが医療機関だけでなく患者側も充実しているということも入社の決め手になりました。自分が患者になることも多いので、とても課題が分かりやすかったんです。`}</p>
    <p><strong parentName="p">{`吉岡`}</strong>{`: 自分も元々エンタメなどの領域より、医療や教育、金融領域を中心に就活をしていました。中でもメドレーの「医療ヘルスケアの未来をつくる」というミッションへの自分の共感度が非常に高かったのが、入社の理由でした。日本の医療制度をきちんと維持するためのプロダクトを作っていくんだという姿勢が非常に良かったです。`}</p>
    <p>{`またさきほどの話にも出ていましたが、`}<strong parentName="p">{`医療機関のためのプロダクトと患者のためのプロダクトという両面へのアプローチをしている`}</strong>{`のも決め手でした。やはり医療費の問題などは医療機関だけへのアプローチでは解決できないと思うので、そういう意味でも一貫性が感じられて良かったです。`}</p>
    <h2>{`現在のチーム内での役割について`}</h2>
    <p><strong parentName="p">{`──`}</strong>{` では次に現在のチームでの皆さんの役割や動き方、チームメンバーとの関わり方について聞いていきたいと思います。`}</p>
    <p><strong parentName="p">{`飯田`}</strong>{`: 現在 Jobley の開発チームではエンジニアが 4 人いるのですが、その内 2 人がプロダクト施策の立案を行ないつつ、自分を含む残りの 2 人が実装をメインにして開発を行なっています。状況に合わせ PM 兼任のエンジニアと実装担当のエンジニアとで二人三脚で開発をしたり、単独での開発を行なったりと柔軟に対応していってます。`}</p>
    <p>{`チームメンバーとの関わりという点では、`}<strong parentName="p">{`エンジニアチームだけで開発するということはなく、セールスやカスタマーサクセスの方たちと一緒に要件を詰めていく`}</strong>{`ことが最近は多いです。そのためセールスの業務フローなどもキャッチアップする必要があるので大変ですが、面白くやりがいを感じるところでもありますね。`}</p>
    <p><strong parentName="p">{`岡田`}</strong>{`: シゴトークでは、PM とエンジニアの二人三脚という体制で開発をしています。PM と日々要件を刷り合わせながら、実装は自分が行なっていていくというスタイルです。Pull Request のレビューだけは元々シゴトークを開発されていたエンジニアの方々にお願いしています。`}</p>
    <p>{`また、例えばプロダクトのセキュリティ強化の取り組みやアプリの認証機能の一新、QA 環境の整備など、自分が普段触れない領域の施策を行った際はスキルアップを実感できました。ライブラリのアップデートや、リファクタリングなども`}<strong parentName="p">{`裁量を持ってやらせてもらっています`}</strong>{`。`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: ジョブメドレーアカデミーはジョブメドレーや CLINICS に比べると小規模なチーム構成となっています。エンジニアが 7 人、PM が 3 人、プロダクトオーナーが 1 人という体制で開発をしてるのですが、`}<strong parentName="p">{`良い意味で新卒入社エンジニアとして裁量を持って開発をさせてもらっています`}</strong>{`。`}</p>
    <p>{`開発チームも徐々に大きくなっているので、タスクを上手く回すための仕組みを考えたり、チームを上手く機能させるために朝会の構成を変更してみたり。技術的な部分では CI/CD など開発全体に関わる分野についても、任せてもらっています。`}</p>
    <p>{`また`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/20230518.html"
      }}>{`ニュースリリース`}</a>{`にもなりましたが、社内で初めての`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2023/11/30/211050"
      }}>{`生成 AI を使った機能の開発`}</a>{`は、プロダクトオーナーと自分の 2 人が中心になって進めていきました。`}<strong parentName="p">{`開発に入る前段階でロジカルに「こういった課題をこの機能で解決していく」ということが伝えられれば、ある程度技術的に実験的なものも導入することができる環境です`}</strong>{`。`}</p>
    <p><strong parentName="p">{`古川`}</strong>{`: Pharms では現在エンジニアが 4 人、PM 1 人、デザイナーが 1 人というのが中心メンバーとなっています。Pharms は現在色々と機能拡大をしていっているのですが、エンジニアが 4 人なので本当に全員で何でもやっていくという感じで開発しています。とはいえ、メンバー各々で得意分野は違うので、得意なところは積極的に拾いに行く感じです。`}</p>
    <p>{`自分はと言うと、得意分野のインフラ部分に関してはほとんど任せてもらっており、自分からの発信で先輩のテックリードエンジニアの方とすり合わせしながら色々な施策を行なっています。その他にもクォーター毎に数個は中・大規模な施策が計画されるので、そうしたもののいくつかのリードをさせてもらったりしています。ですので、`}<strong parentName="p">{`アプリケーションの開発という面でもプロジェクトを上手く進行させるという面でも、とても楽しく業務をしています`}</strong>{`。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eda1d69d63bb3fa6a51cffbc57bb2b23/eea4a/_dev202404__017.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABy0o6i4JM3//EABoQAAMBAAMAAAAAAAAAAAAAAAABAgMSEzH/2gAIAQEAAQUCe7FpVi7kak+RVcf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwFH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BV//EAB0QAAEEAgMAAAAAAAAAAAAAAAABAhIhEBExcZH/2gAIAQEABj8CuW5bGq20Lj7hejlT/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARMSFxsaH/2gAIAQEAAT8hGUtDn5NilqX7FaDV8xp6jStio9p//9oADAMBAAIAAwAAABC//wD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QjD//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8Qsbf/xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhQbExUZHB/9oACAEBAAE/EBiwgZAOmajhCpLx6T8yxZWNkHjeEvNz25YAlEfDTFW+/P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202404  017",
            "title": " dev202404  017",
            "src": "/static/eda1d69d63bb3fa6a51cffbc57bb2b23/e5166/_dev202404__017.jpg",
            "srcSet": ["/static/eda1d69d63bb3fa6a51cffbc57bb2b23/f93b5/_dev202404__017.jpg 300w", "/static/eda1d69d63bb3fa6a51cffbc57bb2b23/b4294/_dev202404__017.jpg 600w", "/static/eda1d69d63bb3fa6a51cffbc57bb2b23/e5166/_dev202404__017.jpg 1200w", "/static/eda1d69d63bb3fa6a51cffbc57bb2b23/eea4a/_dev202404__017.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`村上`}</strong>{`: CLINICS ではまず「基幹」と「周辺」とざっくり 2 つのチームに分かれており、「基幹」が主にレセコン(レセプトコンピュータの略。医療施設から健康保険組合などの支払い機関に対し、診療報酬を請求するために、電子カルテと連動してレセプトと呼ばれる診療報酬明細書を作成するシステム)とのやりとりを、「周辺」では予約や問診、資格確認など CLINICS と患者との接点となるシステム、という形で担当業務が分かれています。自分はずっと基幹チームにアサインされています。チーム全対応のエンジニアの内訳はアプリケーション担当が 6~7 人、インフラ担当が 3 人ほどになっています。`}</p>
    <p>{`現在は大きなプロジェクトが 1 つ進行していて、既存のレセコンをメドレーで新規開発しているレセコンに移行するというものですが、その中に`}<strong parentName="p">{`中規模なプロジェクトが複数平行して進んでいて、その内のいくつかを自分がリード`}</strong>{`させてもらっています。`}</p>
    <p>{`リードしているプロジェクトの進行管理やチームメンバー間での問題共有方法などを考えているのですが、この辺りは挑戦できる幅が広く、難しいですが面白いと感じています。課題についてプロジェクトに限定せず、他のシステムや進捗状況に影響がないかを配慮する必要があり、そうした視点で適切に異なる立場の関係者を巻き込むことで、より包括的な解決策を導き出そうと意識をしています。`}</p>
    <p><strong parentName="p">{`吉岡`}</strong>{`: 自分は今は村上と一緒のチームで業務をしているんですが、レセコンなどの部分というよりは、先程の話の「周辺」の開発をすることが多いです。基本的に平行して複数のプロジェクトが走っており、それぞれのプロジェクトでディレクター 1 人、エンジニア数人、他にデザイナーという形で進めていくことが多いです。`}</p>
    <p>{`大まかにディレクターが要件などをまとめたものをベースとして、その後にデザイナーやエンジニアも入って要件定義などをしていき、徐々に形にしていくという事が多いですね。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` お二人で組んで仕事をすることもあるんですか?`}</p>
    <p><strong parentName="p">{`村上`}</strong>{`: はい、とても多いです。 進行しているプロジェクトの他にも、定常改善やバグ改修などのタスクがありますが、そうしたタスクを「どのタイミングで」「どの優先度で」対応していくかなどをチーム内で議論してから実施するので。`}</p>
    <p><strong parentName="p">{`吉岡`}</strong>{`: プロジェクトやそうしたタスクとは別に、エンジニア主導で大規模なリファクタリングなども行なっています。担当しているプロジェクトの他にそうしたものを手がけていて、一緒になることが多いです。`}</p>
    <h2>{`入社してメドレーの文化を強く意識した出来事`}</h2>
    <p><strong parentName="p">{`──`}</strong>{` メドレーで働いている中で、特に文化を強く感じたエピソードなど聞いてみたいです。`}</p>
    <p><strong parentName="p">{`飯田`}</strong>{`: 開発に関係する人全員が「プロダクト志向が強い」ということでしょうか。具体的には、開発の要件定義段階から全員関わるので、もちろんビジネスサイドの方からも「こうした方が良いのではないか」というアイデアは出てきますが、開発サイドからも「どういう機能が一番ユーザに価値を届けられるか」という視点で一緒になって考えるんです。そもそもどういった課題を解決するものなのかという基礎の部分から一緒に考えていくのがメドレーでは当たり前になっているという感じがします。`}</p>
    <p><strong parentName="p">{`開発サイドもビジネスサイドも同じ方向を向いているので、提案などもちゃんとロジカルで筋が通っているものであれば通るというのは、すごく意見しがいがある`}</strong>{`と思います。そのための相談なんかも、もちろんしやすいですし、とても良い文化だなと感じています。`}</p>
    <p><strong parentName="p">{`岡田`}</strong>{`: 開発にあたって PM が 施策などを Issue
として立ててくれるのですが、言語化が行き届いていて何が問題で、どうして実施するのか、その影響なんかがすごく分かりやすいという点はメドレーが大事にしている「ドキュメントドリブン」という文化を感じます。`}</p>
    <p>{`そのような環境なので、大抵のことは最低限の文章のやり取りだけでスムーズに進みますし、上手くいかない状況になっても善後策などが立てやすくなっていると思います。`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: メドレーは新卒入社の社員数は少ないので、中途入社の方がほとんどという環境です。入社してすぐは「自分の意見は通るのかな」とか「自分より後に入社した経験年数が長い中途入社の方とどう付き合おう」などが心配でした。でも`}<strong parentName="p">{`メドレーでは「建設的に進める」という文化があるので、立場に関係なく議論できる環境があるので、とても仕事がしやすい`}</strong>{`です。自分の意見が例え間違っていたとしても議論が起きるということは、認識がズレていたということだからと軌道修正もできるので本当に働きやすいです。`}</p>
    <p>{`また、とても良いなと思ったのが、部署や職種に関わらず`}<a parentName="p" {...{
        "href": "https://www.atlassian.com/ja/software/confluence"
      }}>{`Confluence`}</a>{`の情報に誰でもアクセスできるという文化ですね。秘匿情報以外は基本的に全て Confluence でオープンになっているおかげで、自分とは直接関係ない部署の現在の動きなどがキャッチアップできるので、そういった人たちとコミュニケーションを取るときにもすごく助かります。`}</p>
    <p><strong parentName="p">{`古川`}</strong>{`: 徳永とかぶるところがあるんですが、「建設的に進める」という部分は非常にメドレーらしさを感じる文化です。例えば PM が「施策をこうしましょう」となっても、自分が「このフローってこうなった方が良いですよね」という意見を言えるし、そこを起点に`}<strong parentName="p">{`議論に発展してより良いものを作っていけるというのは本当に素晴らしい`}</strong>{`と感じています。`}</p>
    <p>{`実際に自分がリードしていた施策の中でもエンジニア・ デザイナー・ PM ・カスタマーサクセスという立場が違うメンバー同士で改めて薬局の業務フローを整理しなおして、より良い仕様にできたりということがありました。 機能リリース後もちゃんと「あるべき姿」を目指して改善をできたりしたので、こういう考え方はとても仕事がしやすいです。`}</p>
    <p><strong parentName="p">{`村上`}</strong>{`: 自分からは「組織水準を高める」という文化について言及させてください。自分が関わっている電子カルテやレセコンという領域は深い業務知識が必要で、初めて関わった人からすると、そもそもどういうフローで機能が使われているかとか、特定の場合に何でこうなるんだろうというところが、理解しづらいんです。でも、自分のチームは新卒・中途問わず、まだ深いドメイン知識がない、といった状態からでも入って活躍しやすい環境だというのを感じています。`}</p>
    <p>{`いきなり専門の業務知識が必要な開発というのはやはり難しいので、そうした部分と直接は関係しない周辺領域の開発から始めてもらって、徐々に電子カルテやレセコンに直接関係する開発に入ってもらえるようなオンボーディングができています。開発の中で、あるべき姿について議論になったりで色々なメンバーと話をしていくので、`}<strong parentName="p">{`医療業務のキャッチアップはかなりしやすい環境`}</strong>{`だと思います。`}</p>
    <p>{`もうひとつ「長期のカスタマー価値を追求する」という部分も文化を感じていて、主にマネージャーを含め、短期的な KPI に沿った開発よりも、長期的に顧客への価値提供に寄与できる開発を優先していくという意識が非常に強いのが良いと感じます。`}</p>
    <p>{`特に今現在、社内で開発が進んでいる新規レセコンの開発については強く思いますね。`}</p>
    <p><strong parentName="p">{`吉岡`}</strong>{`: 自分が強く感じているメドレーの文化としては「ドキュメントドリブン」です。ミーティングなども、こういうことの結論を得るために協議する、というのが明確に分かる資料を事前に用意して行います。ですので、「これ何の時間だっけ?」みたいな状態になりにくいのが良いです。`}</p>
    <p>{`あとは`}<strong parentName="p">{`ミーティングによる同期的、Slack や Confluence などによる非同期のコミュニケーションを適材適所で全員が使いわけているので、そもそもコミュニケーションで無駄な行き違いのようなものも、ほとんど無い`}</strong>{`のも、仕事がしやすいと思っています。`}</p>
    <p>{`もうひとつは、自分もやはりプロダクト志向の強さというところを感じています。現在はディレクターなどと一緒に開発をしていく体制になっているんですが、UI や顧客体験なども含めてエンジニアでも、ちゃんとプロダクトとしての理想の姿を考えて開発するという姿勢がとても強いです。自分の専門ではない領域でもきちんと考えながら開発をしていくので、やりがいがあります。`}</p>
    <h2>{`これからメドレーに入社する方へのメッセージ`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/377766efff306a3b299dc9cd65b269c1/eea4a/_dev202404__020.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDAf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAG8WQ0cj//EABkQAQADAQEAAAAAAAAAAAAAAAIAERIBA//aAAgBAQABBQK6ffVci9aW1rahSn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAgEAACAQEJAAAAAAAAAAAAAAAAATEyAhITIjNRYZGh/9oACAEBAAY/ArKuzwaTjYaw/CplT7Hmcn//xAAdEAEAAQQDAQAAAAAAAAAAAAABABEhQbFRcYGR/9oACAEBAAE/IRotuVPiVwM7tWPVFoLPLzHz9orYT//aAAwDAQACAAMAAAAQdz//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Qqv/EABsQAQEBAQEAAwAAAAAAAAAAAAERACFRQYGR/9oACAEBAAE/EDz9WQPhiEqSbjyn5h2I4VN4ajxWS/MEJ9p43erEFe//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202404  020",
            "title": " dev202404  020",
            "src": "/static/377766efff306a3b299dc9cd65b269c1/e5166/_dev202404__020.jpg",
            "srcSet": ["/static/377766efff306a3b299dc9cd65b269c1/f93b5/_dev202404__020.jpg 300w", "/static/377766efff306a3b299dc9cd65b269c1/b4294/_dev202404__020.jpg 600w", "/static/377766efff306a3b299dc9cd65b269c1/e5166/_dev202404__020.jpg 1200w", "/static/377766efff306a3b299dc9cd65b269c1/eea4a/_dev202404__020.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`──`}</strong>{` では最後になりますが、特に新卒でメドレーの入社を考えている方へのメッセージなどあれば、お伺いしたいです。`}</p>
    <p><strong parentName="p">{`飯田`}</strong>{`: そうですね、「先輩が新卒に対してとても期待してくれている」というのは伝えたいと思います。自分の場合、研修が終わって 3 ヶ月経ったぐらいで、シゴトークのインフラ環境を全部刷新するという大きなプロジェクトのリードを任せてもらえたんです。もちろん SRE の先輩などと協力しつつ進めたものだったんですが、`}<strong parentName="p">{`研修終わってすぐにそんな大きなプロジェクトを裁量持って進められるような環境なので、すごくやりがいがあります`}</strong>{`。`}</p>
    <p><strong parentName="p">{`岡田`}</strong>{`: 稲本(人材プラットフォーム CTO)が「新卒だから」みたいな接し方をせずに、いちエンジニアとして接してくれているのは個人的に嬉しいポイントです。また`}<strong parentName="p">{`責任を持った上で裁量持って自由に動いていける業務環境があるのが、すごく良い`}</strong>{`です。ちゃんとプロダクトの事を考えて開発をしたい人にはおすすめできる環境だと思います。`}</p>
    <p><strong parentName="p">{`徳永`}</strong>{`: 新卒の良いところは「同期」感を持てるところかなと思います。今もランチ等でこのメンバーと技術的なところなどを気軽に相談できるのはとても良いです。`}<strong parentName="p">{`切磋琢磨していく環境じゃないかなと思っているので、そうした環境を求めるならすごく良い`}</strong>{`と思います。`}</p>
    <p><strong parentName="p">{`古川`}</strong>{`: 受け身で仕事を行うのではなく、ガツガツと自分の方から求めにいく姿勢の人には、すごくマッチする会社だと思います。せっかく裁量持って業務を進めることができる環境なので、`}<strong parentName="p">{`遠慮せずにがんがんと物事進めることができる人には最高`}</strong>{`なんじゃないかと考えています。`}</p>
    <p>{`自分はインフラだけではなくて、サーバやフロントエンドも開発していきたいと考えているんですが、小規模なプロジェクトであれば全て行える環境なため、自分一人でプロジェクトを完結することができるのは、性に合っているなと思ってます。周りのサポートや先輩との壁打ちなどはちゃんとやってもらえるので、とても勉強になり成長できると思います。`}</p>
    <p><strong parentName="p">{`村上`}</strong>{`: メドレーで開発をしていくについれて、医療ドメインという領域で自分が関わっていることが人の役に立っているんだなというのが日々実感できるところは良いです。特に色々な医療機関のサポートができるというプロダクトに関わっているので、直接医療機関やその先にいる患者さんの`}<strong parentName="p">{`役に立っているという実感は、正直 1 年目で感じられるものではないと思っていたので、良い驚き`}</strong>{`です。`}</p>
    <p>{`また大きいデータを取り扱うという経験ができているのも貴重だなと感じています。現在のプロダクトでは契約医療機関も非常に多いですし、その大量のデータの設計を変更したりというところを、裁量持って実施できるのは良い経験になっています。`}</p>
    <p><strong parentName="p">{`吉岡`}</strong>{`: メドレーの「プロダクト志向」を持っている先輩エンジニアと働けるというのはとても良い経験だと思っています。一般的にはエンジニアは技術さえあればという感じになりやすかったりすると思うのですが、メドレーではもちろん新しい技術なども使いながら、それを`}<strong parentName="p">{`いかにプロダクトやその先のユーザへの価値提供に繋げるかを考えている方が集っている`}</strong>{`ので、そういう思考でプロダクト開発ができているのはすごく勉強になります。`}</p>
    <p><strong parentName="p">{`──`}</strong>{` ありがとうございました! 本当に 1 年で皆さんが濃い業務経験を積んでいるのが分かるインタビューになりました。`}</p>
    <h2>{`さいごに`}</h2>
    <p>{`それぞれ異なるチームや業務内容ですが、新卒エンジニアでも裁量を持って業務に臨めることや、プロダクトの重要な部分の開発にどんどん関わっていけることが伝わるインタビューとなりました。`}</p>
    <p>{`メドレーは新卒入社のエンジニアも積極的に募集していますので、今回気になったという方はお気軽にカジュアル面談をしましょう!`}</p>
    <p><a parentName="p" {...{
        "href": "https://open.talentio.com/r/1/c/medley/pages/54863"
      }}>{`https://open.talentio.com/r/1/c/medley/pages/54863`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      